@font-face {
  font-family: 'Roboto-Black';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Black'), url('././fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-BlackItalic'), url('././fonts/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Bold'), url('././fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-BoldItalic'), url('././fonts/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Italic';
  font-display: auto;
  font-style: italic;
  font-weight: normal;
  src: local('Roboto-Italic'), url('fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Light'), url('././fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-LightItalic';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-LightItalic'), url('././fonts/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Medium'), url('././fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-MediumItalic'), url('././fonts/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Regular'), url('././fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Thin';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Thin'), url('././fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-ThinItalic'), url('././fonts/Roboto-ThinItalic.ttf') format('truetype');
}

body {
  margin: 0;
  font-family:'Roboto-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




