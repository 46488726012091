.MainFont {
    max-width: 1000px;
    margin: auto;
}
.App {
    text-align: center;
}

body {
    background-color: #ececec;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
#TaskHolder {

}
.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.LoginFormBackGround {
    background-color: #F1F1F1;
}

.LoginLogoBackGround {
    background-color: #0d6efd;
    width: 100%;
    height: 150px;
    text-align: center;
}

.NewTaskForm {
    background-color: #0d6efd;
    width: 100%;
    height: 70px;
    text-align: center;
}

.big {
    font-size: 1.2em;
}

.Custom-dropdown select {
    background-color: #0d6efd;
    font-size: 12px;
    width: 100%;
    color: #F1F1F1;
    outline-color: #0d6efd;
    border-radius: 5px;
    border: #0d6efd;
    height: 25px;
    appearance: none;
    size: 3px;
    overflow-y: scroll
}

.dropdown-menu {
    height: 130px;
    overflow: auto;
    width: 100%;
}


.Custom {
    background-color: #0d6efd;
    font-size: 17px;
    width: 100%;
    color: #F1F1F1;
    outline-color: #0d6efd;
    border-radius: 5px;
    border: #0d6efd;
    height: 35px;
    -moz-appearance: none;
    size: 3px;
    overflow-y: scroll
}



.LoginLogo {
    width: 90px;
    height: 90px;
    margin-top: 10px;
}

.LoginForm {
    background-color: #FFFFFF;
    width: 75%;
    padding: 20px 20px 5px 20px;
    margin: -40px auto 50%;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(122, 122, 122, 0.5);
}

.NewTaskForm1 {
    background-color: #FFFFFF;
    width: 95%;
    padding: 20px 20px 5px 20px;
    margin: -40px auto 50%;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(122, 122, 122, 0.5);
}

.LoginIFLables {
    font-size: 15px;
    color: #9F9F9F;
    margin-left: 11px;
}

.LoginIFBox {
    font-size: 17px;
    border: 0;
    border-bottom: 2px solid #0d6efd;
    border-radius: 0;
    color: #0d6efd;
}

.taskIFBox {
    font-size: 17px;
    border: 0;
    border-bottom: 2px solid #0d6efd;
    border-radius: 0;
    color: #0d6efd;
    width: 100%;
}

.Calendar {
    color: #0d6efd;
    font-size: 37px;
}

.CalendarA {
    margin-left: 90%;
    margin-top: -150px;
    position: relative;

}

.NewTaskFormBox {
    font-size: 17px;
    border: 0;
    border-bottom: 2px solid #0d6efd;
    border-radius: 0;
    color: #0d6efd;
}

input::placeholder {
    font-size: 14px;
    text-align: left;
    width: 100%;
    font-style: italic;
    color: gray;
}

.TaskDateAndTimeLables {
    font-size: 15px;
    color: gray;
    text-align: left;
    width: 100%;
}

.SelectDivisionLables {
    font-size: 15px;
    color: gray;
    text-align: left;
    width: 100%;
}

.LoginButton {
    font-size: large;
    border-radius: 10px;
    width: 100%;
}

.TaskCreateButton {
    font-size: 14px;
    margin-right: auto;
    width: 49%;
    float: left;
    background-color: #0d6efd;
    border-color: #ced4da;
}

.CancelButton {
    font-size: 14px;
    margin-right: auto;
    width: 49%;
    float: right;
    background-color: #0d6efd;
    border-color: #ced4da;
}

.radiusBtn {
    width: 50px;
    position: absolute;
    z-index: 1000;
}

.mapBackBtn {
    position: absolute;
    z-index: 1000;
}

.LoginLabel {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #44C553;
}

.LoginSettingsBtn {
    width: 25px !important;
    height: 25px;
    color: #9F9F9F;
}

.LoginSettingsBtn:hover, .LoginSettingsBtn:focus {
    color: #8d8d8d;
}

.LoginSettingsBtnHolder {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
}

.CommutationClose {
    width: 100%;
    color: #F1F1F1;
    background-color: #dc3545;
    outline-color: #dc3545;
    border-color: #dc3545;
    border-radius: 5px;
    font-size: 15px;

}

.CommutationForm {
    background-color: #FFFFFF;
    width: 100%;
    height: 95%;
    border-radius: 5px;
}

.CommutationButton {
    width: 95%;
    color: #F1F1F1;
    border-color: #0d6efd00;
    outline-color: #0d6efd;
    background-color: #0d6efd;
    border-radius: 5px;
    margin-bottom: 5px;
}

.CommutationButtonPhoto {
    width: 95%;
    height: 35px;
    color: #F1F1F1;
    border-color: #0d6efd1f;
    outline-color: #0d6efd;
    background-color: #0d6efd;
    border-radius: 5px;
    margin-bottom: 5px;
}

.CommutationIMGPhoto {
    width: 98%;
}

.DialogBody {
    font-size: large;
}

.DialogIcon {
    color: #0d6efd;
    margin-right: 10px;
}

.MainBG {
    background-color: #F1F1F1;
    position: absolute;
    width: 100%;
    height: 100%;
}

.AppBarCenterBtn {
    font-size: 25px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 100px !important;
    width: 55px;
    height: 55px;
    margin: -27px auto 50%;
}

.AppBarMidle {
    width: 20%;
    height: 100%;
    background-color: white;
    position: absolute;
    left: 40%;
    text-align: center;
}

.AppBarRight {
    width: 40%;
    height: 100%;
    background-color: white;
    position: absolute;
    right: 0;
}

.AppBarLeft {
    width: 40%;
    height: 100%;
    background-color: white;
    position: absolute;
    left: 0;
}

.AppBar {
    height: 50px;
    width: 100%;
    background-color: #FFFFFF;
    position: absolute;
    bottom: 0;
    box-shadow: 0 0 15px rgba(122, 122, 122, 0.5);
    line-height: 50px;
}

.btn-circle {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    font-size: 32px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    left: calc(50% - 30px);
    bottom: 40px;
}

.navbar {
    height: 70px;
    max-width: 968px;
    margin: auto;
}

.navbar-brand {
    font-size: 26px;
}

.navbar-top {
    height: 30px;

    /*margin-top: 5px;*/
}

.navbar-top-div {
    margin-top: -5px;
}

.mb-8-5 {
    margin-bottom: 85px;
}

.blockBottomTask {
    background-color: #0d6efd;
    color: white;
    text-align: left;
}

.blockBottomTaskD {
    background-color: #f70000;
    color: white;
    text-align: left;
}

.textwrap {
    word-wrap: break-word;
}

.taskQM {
    font-size: 25px;
}

.taskPriority {
    width: 15px;
}

.react-calendar {
    border: 0 !important;
}

.objRotate {
    -webkit-animation-name: rotation;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotation;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -o-animation-name: rotation;
    -o-animation-duration: 1s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
    animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(720deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.signalsDialog {
    max-height: 500px;
    overflow-y: scroll;
}

@media (max-width: 640px) {
    .smallText {
        font-size: 13px;
    }
}

.nav-xbootstrap {
    background: #fff;
    width: 300px;
    height: 100%;
    display: block;
    position: fixed;
    left: -300px;
    top: 0px;
    -webkit-transition: left 0.25s ease;
    -moz-transition: left 0.25s ease;
    -ms-transition: left 0.25s ease;
    -o-transition: left 0.25s ease;
    transition: left 0.25s ease;
    margin: 0;
    border: 0;
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.title-mobile {
    position: fixed;
    display: block;
    top: 10px;
    font-size: 20px;
    left: 100px;
    right: 100px;
    text-align: center;
    color: #FFF;
}

.nav-xbootstrap.visible {
    left: auto;
    -webkit-transition: left 0.25s ease;
    -moz-transition: left 0.25s ease;
    -ms-transition: left 0.25s ease;
    -o-transition: left 0.25s ease;
    transition: left 0.25s ease;
}

.iconsize {
    font-size: 12px;
    top: 2px;
    left: 3px;
}

.nav-xbootstrap ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: block;
}

.nav-xbootstrap li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    font-size: 14px;
    color: #def1f0;
}

.nav-xbootstrap li a {
    margin: 1px 1px 0px 10px;
    font-size: 14px;
    color: #def1f0;
    display: inline-block;
    outline: 0;
    font-weight: 400;
    text-decoration: none;
    text-align: left;
    height: 37px;
    padding-top: 8px;
}

.nav-xbootstrap li:hover ul.dropdown {
    display: block;
}

.nav-xbootstrap li ul.dropdown {
    position: absolute;
    display: none;
    width: 200px;
    background: #2980B9;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    padding-top: 0;
}

.nav-xbootstrap li ul.dropdown li {
    display: block;
    list-style-type: none;
}

.nav-xbootstrap li ul.dropdown li a {
    padding: 15px 20px;
    font-size: 15px;
    color: #fff;
    display: block;
    font-weight: 400;
}

.nav-xbootstrap li ul.dropdown li:last-child a {
    border-bottom: none;
}

.nav-xbootstrap li:hover a {
    background: #2980B9;
    color: #fff !important;
}

.nav-xbootstrap li:first-child:hover a {
    border-radius: 3px 0 0 3px;
}

.nav-xbootstrap li ul.dropdown li:hover a {
    background: rgba(0, 0, 0, .1);
}

.nav-xbootstrap li ul.dropdown li:first-child:hover a {
    border-radius: 0;
}

.nav-xbootstrap li:hover .arrow-down {
    border-top: 5px solid #fff;
}

.nav-bg-xbootstrap {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 50px;
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    background: #03A9F4;
    padding: 12px 0 0 10px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.navbar-xbootstrap {
    display: inline-block;
    vertical-align: middle;
    height: 50px;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
}

.navbar-xbootstrap span {
    height: 2px;
    background: #fff;
    margin: 5px;
    display: block;
    width: 20px;
}

.navbar-xbootstrap span:nth-child(2) {
    width: 20px;
}

.navbar-xbootstrap span:nth-child(3) {
    width: 20px;
}

.nav-xbootstrap ul {
    padding-top: 50px;
}

.nav-xbootstrap li {
    display: block;
}

.nav-xbootstrap li a {
    display: block;
    color: #505050;
    font-weight: 600;
}

.nav-xbootstrap li:first-child:hover a {
    border-radius: 0;
}

.nav-xbootstrap li ul.dropdown {
    position: relative;
}

.nav-xbootstrap li ul.dropdown li a {
    background: #2980B9 !important;
    border-bottom: none;
    color: #fff !important;
}

.nav-xbootstrap li:hover a {
    background: #03A9F4;
    color: #fff !important;
}

.nav-xbootstrap li ul.dropdown li:hover a {
    background: rgba(0, 0, 0, .1) !important;
    color: #fff !important;
}

.nav-xbootstrap li ul.dropdown li a {
    padding: 10px 10px 10px 30px;
}

.nav-xbootstrap li:hover .arrow-down {
    border-top: 5px solid #fff;
}

.arrow-down {
    border-top: 5px solid #505050;
    position: absolute;
    top: 20px;
    right: 10px;
}

.cover-bg {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.bg-black {
    background-color: #00000077;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    transition: 0.5s;
}

.swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
}


.swipe-wrap {
    overflow: hidden;
    position: relative;
}

.swipe-wrap > div > img {
    float: left;
    width: 150px !important;
    position: relative;
}

.bigImage {
    width: 100%;
}

.modal-fix > div > div > div {
    transform: unset;
    min-height: 300px;
}

.CommentCloseBtn {
    width: 30%;
    color: #F1F1F1;
    background-color: red;
    outline-color: red;
    border-color: red;
    border-radius: 5px;
    height: 37px;
}

.CommentSendBtn {
    width: 30%;
    color: #F1F1F1;
    background-color: #0d6efd;
    outline-color: #0d6efd;
    border-color: #0d6efd;
    border-radius: 5px;
    height: 37px;
}

.btn-secondary {
    width: 100%;
    background-color: #0d6efd;
    border-color: #ced4da;
    color: #F1F1F1;
    white-space: nowrap;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}



.NewTaskLabel {
    color: #fff;
    background-color: #0d6efd;
    border-color: #ced4da;
    padding: 0.400rem 0.75rem;
    font-size: 1.5rem;
    line-height: 1.2;
    border-radius: 0.25rem;
    margin: -1% auto 1%;
    float: left;

}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid white;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.form-controlDate {
    display: block;
    font-size: 15px;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: gray;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bigImageBtn {
    color: #fff;
    background-color: #0d6efdcf;
    border-color: #0d6efdcf;
    width: 45%;
    border-spacing: 5px;
    margin: 5px;
}

.bigImageBtn1 {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    width: 45%;
    border-spacing: 5px;
    margin: 5px;
}

.bigImageBtn2 {
    color: #fff;
    background-color: #0d6efdcf;
    border-color: #0d6efdcf;
    width: 20%;
    border-spacing: 5px;
    margin-left: 10%;
}

.NewTaskFormCamera {
    background-color: #0d6efdcf;
    border-color: #0d6efd00;
    color: #F1F1F1;
    height: 42px;
    width: 42px;
    border-radius: 0.25rem;
    margin-left: -72%;
    padding: 0.400rem 0.75rem;
    font-size: 1.5rem;
    line-height: 1.2;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

.btn-dangerSend {
    width: 47%;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    margin: 3px;
}

.btn-primarySend {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    width: 47%;
    margin: 3px;
}

.btn-dangerSendComments {
    width: 47%;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-dangerCancelTask {
    width: 47%;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    font-size: 15px;
    min-height: 35px;
}

.btn-dangerFilter {
    width: 50%;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-dangerInfrastructure {
    width: 98%;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    background-position: right;
}

.btn-dangerSendCommentsOk {
    width: 47%;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.form-controlComments {
    display: block;
    width: 100%;
    height: 7rem;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-position: center;
}

.SupportsMapTextarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-position: center;
    min-height: 80px;
}

textarea::placeholder {
    font-style: italic;
    font-size: 14px;
}

.image {
    width: 100%;
}

.img {
    width: 50%;
}

.YamapForm {
    background-color: #FFFFFF;
    width: 100%;
    padding: 40px 20px 5px 20px;
    margin: -40px auto 50%;
    box-shadow: 0 0 15px rgba(122, 122, 122, 0.5);
    position: absolute;
}

.YamapBack {
    width: 80%;
    color: #F1F1F1;
    background-color: #0d6efd;
    outline-color: #0d6efd;
    border-color: #0d6efd;
    border-radius: 5px;
    font-size: 15px;
    margin: 5px;
    height: 37px;
}

.YamapBackAbon {
    width: 100%;
    color: #F1F1F1;
    background-color: #0d6efd;
    outline-color: #0d6efd;
    border-color: #0d6efd;
    border-radius: 5px;
    font-size: 15px;
}

.YamapRange {
    width: 35%;
    color: #F1F1F1;
    background-color: #0d6efd;
    /* outline-color: #0d6efd;*/
    border-color: #0d6efd00;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px;
    min-height: 37px;
}

.YamapInput {
    width: 35%;
    color: #F1F1F1;
    background-color: #0d6efd;
    outline-color: #0d6efd;
    border-color: #0d6efd;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px;
    font-weight: bold;
}

.Yamap {
    width: 100%;
    height: 420px;
}

.m-0 {
    font-size: 12px;
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    font-family: 'Roboto-Regular';

}

/***удаляем надписи на карте яндекса***/
.ymaps-2-1-79-gotoymaps__container,
.ymaps-2-1-79-gototech {
    display: none !important;
}

/***конец удаления надписи на карте яндекса***/

.Demo-swiper {
    max-width: 100%;
    min-height: 100px;
    height: auto;
    box-shadow: 0px 0px 1px 0px rgb(170, 170, 170);
    background: #eee;
    position: relative;
}

.Demo-swiper__slide {
    display: block;
    text-align: center;
    min-height: 100px;
    height: auto;
    width: 100%;
    line-height: 70px;
    margin-top: 10px;
    padding-left: 15px;
    padding-bottom: 10px;

}

.DivNewTask {
    font-weight: bold;
    text-align: center;
    padding: 5px 5px;
    background: #fff;

}

.classImg {
    width: 100%;
}

.swiper-button-prev, .swiper-button-next {
    position: absolute;

    width: 27px;
    height: 20px;
    margin-top: -25px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 50px;
    background-position: center;
    background-repeat: no-repeat;

}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #0d6efd;
    text-shadow: 0 1px 0 #0d6efd;
    opacity: .8;
}

.close {
    padding-right: 3px;
}

.SubscriberMapForm {
    background-color: #FFFFFF;
    width: 100%;
    padding: 50px 20px 5px 20px;
    margin: -40px auto 50%;
    box-shadow: 0 0 15px rgba(122, 122, 122, 0.5);
    position: absolute;
}

.InfrastructureMapForm {
    background-color: #FFFFFF;
    width: 100%;
    padding: 50px 20px 5px 20px;
    margin: -40px auto 50%;
    box-shadow: 0 0 15px rgba(122, 122, 122, 0.5);
    position: absolute;
}

.PlacemarkButton {
    width: 100px;
    color: #F1F1F1;
    background-color: #0d6efd;
    outline-color: #0d6efd;
    border-color: #0d6efd;
    border-radius: 5px;
    font-size: 15px;
}

.InfrastructureButton {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff12;
    border-radius: 5px;
    font-size: 15px;
    width: 98%;
    margin: 1px 0px 1px 0px;
}


.balloonButton {
    border-radius: 5px;
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    float: right;
}

.FilterButton {
    width: 50%;
}

.lll {
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.TaskCalendarForm {
    width: 100%;
    background-color: #FFFFFF;
    padding: 20px 20px 5px 20px;
    margin: -40px auto 50%;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(122, 122, 122, 0.5);
}

.OverdueButton {
    width: 50%;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    border-radius: 5px;
    height: 40px;
}

.OverdueButton2 {
    width: 50%;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    border-radius: 5px;
    height: 40px;
}

.modal-headers {
    /* display: flex; */
    align-items: flex-start;
    /* justify-content: space-between; */
    padding: 10px 10px;
    /* border-bottom: 1px solid #dee2e6; */
    /* border-top-left-radius: calc(0.3rem - 1px); */
    /* border-top-right-radius: calc(0.3rem - 1px); */
}

.dropdown-item1 {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 10px;
}

.dropdown-item2 {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 10px;
}


.btn-secondarys {
    width: 100%;
    background-color: #0d6efd;
    border-color: #ced4da;
    color: #F1F1F1;
    font-size: 10px;
    /* line-height: 1.5;*/
    border-radius: 0.25rem;
    min-height: 35px;
}

.btn-secondaris {
    width: 100%;
    background-color: #0d6efd;
    border-color: #ced4da;
    color: #F1F1F1;
    font-size: 10px;
    border-radius: 0.25rem;
    height: 35px;
}

/*.btn-Filter {
  width: 50%;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  font-size: 15px;
}*/

.FilterBtn {
    width: 50%;
    font-size: 15px;
}

.TaskClose {
    font-size: 15px;
    color: gray;
    width: 100%;
    text-align: left;
}

.modal-headerCloseTask {
    /* display: flex; */
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.btn-dangerCloseTask {
    width: 47%;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    font-size: 15px;
    min-height: 35px;
}

.dropdown-itemCloseTask {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 15px;
}



.btn-secondTask {
    width: 96%;
    background-color: #0d6efd;
    border-color: #ced4da;
    color: #F1F1F1;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    line-height: 1.4;
    border-radius: 0.25rem;
}

/*.task{
  width: 96%;
  height: 100px;
}*/

.taski {
    width: 91%;
    height: 80px;
}

.alignLeft {
    text-align: left;
}

.float-leftTemplate {
    list-style-type: none;
    text-align: left;
}

.AssignmentsFor {
    text-align: left;
    color: #212529;
    font-size: 17px;
    width: 100%;
}

.TaskCalendar {
    width: 100%;
    text-align: left;
    padding: 8px 5px;
    border: 1px solid #d0cece;
    font-size: 17px;
    border-radius: 0.25rem;
    height: 41px;
    margin: 2px 0px 2px 0px;
    background-color: #e9ecef;
}

.ONUDialogLabel {
    width: 100%;
    color: #0d6efd;
    margin-top: 7px;
}

.ONUDialogA {
    width: 100%;
    text-align: left;
    padding: 5px 5px;
    border: 1px solid #d0cece;
    font-size: 15px;
    border-radius: 0.25rem;
    height: 37px;
    background-color: #e9ecef;
}

.flex_svaz {
    /*flex-direction: row-reverse;*/
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-gap: 1em;
}

.flex_svaz button {
    padding: 1em;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1),
    0 20px 20px rgba(0, 0, 0, 0.05);
}

.ove_sc {
    overflow: scroll;
}



.ObserversDiv {
    width: 100%;
    border: 1px solid #9f9f9f4f;
    border-radius: 5px;
    min-height: 36px;
    margin: 4px 0px 4px 0px;
}

.btn-ObserversClose {
    width: 47%;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    font-size: 15px;
}

.ObserversClose {
    width: 47%;
    font-size: 15px;
}

.ObserversButton {
    width: 98%;
    border-radius: 5px;
    margin: 4px 4px 4px 4px;
    min-height: 35px;
    font-size: 16px;
    padding-left: 5px;
    padding-top: 4px;
    background-color: #e9ecef;
    border: 1px solid #d0cece;
    text-align: left;
}

.MarketingInfo {
    width: 98%;
    text-align: center;
    padding: 5px 5px;
    border: 1px solid #ffffff;
    /* border: 1px solid #9f9f9f4f;*/
    font-size: 15px;
    border-radius: 0.20rem;
    min-height: 37px;
    background-color: #ffffff;
    margin: 4px 4px 4px 4px;
}

.DotText {
    text-align: center;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}

/*.DotButton{
  width: 100%;
  margin: 4px 0px 15px 0px;
}*/

.Dot {
    min-height: 35px;
    border-radius: 5px;
    margin: 4px 0px 15px 0px;
}

.ObjectsButton {
    width: 100%;
    color: #FFFFFF;
    border-color: #007bff00;
    background-color: #007bff;
    border-radius: 5px;
    min-height: 32px;
    padding: 5px 5px;
    margin: 2px 0px 2px 0px;
    text-align: center;
}

/*.balloonKnotTitle{
  border: 2px solid #ff000000;
  width: 230px;
  min-height: 23px;
  font-size: 15px;
  border-bottom-color: #9f9f9f4f;
  text-align: center;
}*/

.balloonKnot {
    font-size: 16px;
    margin: 6px 0px 6px 0px;
}

.KnotButton {
    width: 100%;
    border-radius: 5px;
    /* color: #FFFFFF;
     background-color: #007bff;
     border-color: #007bff00;*/
    text-align: left;
    margin: 2px 0px 1px 0px;
    font-size: 13px;
    min-height: 30px;
    border: 1px solid #9f9f9f5e;
    background-color: #e9ecef;
    color: #212529;
}

.SupportSize {
    font-size: 12px;
}

.ColorSupportsIcon {
    color: #0d6efd;
    padding: 5px 5px;
}

.SupportDiv {
    width: 98%;
    text-align: left;
    padding: 2px 2px;
    border: 1px solid #ffffff;
    font-size: 15px;
    border-radius: 0.20rem;
    height: 37px;
    background-color: #ffffff;
    margin: 4px 4px 4px 4px;
}

.SupportDivIcons {
    width: 98%;
    text-align: left;
    padding: 5px 5px;
    border: 1px solid #ffffff;
    font-size: 15px;
    border-radius: 0.20rem;
    height: 55px;
    background-color: #ffffff;
    margin: 20px 4px 20px 4px;
}

.SupportsInformation {
    width: 100%;
    color: #0d6efd;
    font-size: 14px;
    text-align: left;
    padding-left: 10px;
    margin: 6px 0px 2px 0px;
}

.SupportDivInfo {
    width: 97%;
    text-align: left;
    padding: 2px 2px;
    border: 1px solid #ffffff;
    font-size: 15px;
    border-radius: 0.2rem;
    min-height: 31px;
    background-color: #ffffff;
    margin: 0px 0px 0px 6px;
}


.CameraSupports {
    color: #007bff;
    background-color: #ffffff;
    border-color: #ffffff;
    padding: 0.4rem 0.75rem;
    font-size: 1.5rem;
    border-radius: 0.25rem;
    float: left;
}

.container-color {
    display: flex;
    flex-direction: column;
    margin: 20px auto 60px auto;
    width: 95%;

}

.color-settings {
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.color-for-task {
    width: 95%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 8px;
}

.task-type-container {
    width: 100%;
    display: flex;
    justify-content: start;

}

.task-type-color {
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: break-all;

}

.choose-clr-container {
    display: flex;
    justify-content: right;
width: 100%

}


.color-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: #007bff;
    color: #007bff;
    width: 20%;
    /*height: 100%;*/

/*border-radius: 50%;*/

}
.change-color-btn {
    font-size: 0.8em;
}

.color-save {
    width: 50%;
}

.color-close {
    width: 50%;
}

.ColorPicker {
    display: flex;
    align-items: center;
    background: white;

}

.custom-layout {
    aspect-ratio: 1/1;
}

/* Custom layout */
.custom-layout .react-colorful {
    padding: 16px;
    background: white;
    box-shadow: 0 6px 12px #999;
}

.color-modal-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
}


.custom-pointers .react-colorful__saturation-pointer {
    width: 16px;
    height: 16px;
    border-radius: 3px;
}

.custom-pointers .react-colorful__hue-pointer,
.custom-pointers .react-colorful__alpha-pointer {
    width: 16px;
    border-radius: 3px;
}

/* Custom layout */
.custom-layout .react-colorful {
    padding: 16px;
    background: white;
    box-shadow: 0 6px 12px #999;
    width: 100%;
    height: 100%;
}


.custom-layout .react-colorful__saturation {
    margin: 15px 0;
    border-radius: 5px;
    border-bottom: none;
}

.custom-layout .react-colorful__hue {
    order: -1;
}

.custom-layout .react-colorful__hue,
.custom-layout .react-colorful__alpha {
    height: 14px;
    border-radius: 5px;
}

.custom-layout .react-colorful__hue-pointer,
.custom-layout .react-colorful__alpha-pointer {
    width: 20px;
    height: 20px;
}

.reset-color {
    font-size: 0.8em;
    width: 95%;
    padding: 0;
    margin-top: 10px;
}



.sectionCatalog{
    width: 98%;
    color: #FFFFFF;
    border-color: #0d6efd00;
    background-color: #0d6efd;
    margin: 4px 0px 4px 0px;
    border-radius: 5px;
    min-height: 30px;
    text-align: left;
    padding-left: 10px;
    padding-top: 2px;
    margin-left: 5px;
}
.divCol{
    width: 100%;
    font-size: 10px;
    margin: 4px 2px 4px 2px;
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    border-radius: 5px;
    font-weight: bold;
}
.columns{
    height: 25px;
    font-size: 10px;
    margin: 4px 0px 4px 0px;
    font-weight: normal;
}

.columns{
    height: 25px;
    font-size: 10px;
    margin: 4px 0px 4px 0px;
    font-weight: normal;
}

.columns2 {
    height: 30px;
    font-size: 11px;
    margin: 8px 0px 10px 0px;
    font-weight: normal;
    text-align: left;
}

.colState {
    height: 25px;
    font-size: 11px;
    margin: 8px 0px 6px 0px;
    font-weight: bold;
    text-align: left;
    color: #44C553;
}

.colState2 {
    height: 25px;
    font-size: 11px;
    margin: 8px 0px 6px 0px;
    font-weight: bold;
    text-align: left;
    color: darkorange;
}

.colState3 {
    height: 25px;
    font-size: 11px;
    margin: 8px 0px 6px 0px;
    font-weight: bold;
    text-align: left;
    color: #f70000;
}

.columns3{
    height: 30px;
    font-size: 11px;
    margin: 8px 0px 10px 0px;
    font-weight: normal;
}

.columnsState{
    height: 25px;
    font-size: 11px;
    margin: 8px 0px 6px 0px;
    font-weight: normal;
}

.columns4{
    height: 30px;
    font-size: 11px;
    margin: 8px 0px 10px 0px;
    font-weight: bold;
    text-align: left;
}

.divCol{
    width: 100%;
    font-size: 10px;
    margin: 4px 2px 4px 2px;
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    border-radius: 5px;
    font-weight: bold;
}

.divCol2{
    width: 100%;
    font-size: 11px;
    margin: 4px 2px 4px 2px;
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    border-radius: 5px;
    font-weight: bold;
    text-align: left;
}

.nnnn{
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    border-radius: 5px;
}

.menu-shift-btn {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin: 1px 1px 0px 10px;
    padding: 0;
    border: none;
    background-color: transparent;
    width: 100%;
}




.balloonI{
    border-radius: 5px;
    border: 1px solid #9f9f9f6e;
    width: 100%;
    min-height: 30px;
    font-size: 14px;
    background-color: #f0f2f5;
    padding-top: 3px;
    margin: 2px 0px 2px 0px;
    padding-left: 5px;
}
.balloonIn{
    width: 100%;
    color: #0d6efd;
    font-size: 14px;
    font-weight: bold;
}
.balloonInfa{
    border-radius: 5px;
    border: 1px solid #9f9f9f6e;
    width: 100%;
    min-height: 35px;
    font-size: 14px;
    background-color: #f0f2f5;
}
.Navigator{
    width: 47%;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd00;
    font-size: 15px;
    min-height: 36px;
    border-radius: 3px;
}
.showFotoClass{
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd00;
    border-radius: 3px;
    min-height: 35px;
    width: 48%;
    font-weight: normal;
    font-size: 12px;
    padding-left: 4px;
    padding-top: 4px;
    margin: 10px 4px 10px 2px;
}

.hideFotoClass{
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd00;
    border-radius: 3px;
    min-height: 35px;
    width: 48%;
    font-weight: normal;
    font-size: 12px;
    padding-left: 4px;
    padding-top: 4px;
    margin: 10px 4px 10px 2px;
}
.taskOnMap{
    border-radius: 5px;
    border: 1px solid #9f9f9f4f;
    min-width: 200px;
    min-height: 30px;
    font-size: 15px;
    margin: 4px 0px 4px 0px;
    padding-left: 5px;
    padding-top: 3px;
}
.MarketingButton{
    color: #fff;
    background-color: #007bff;
    border-color: #007bff00;
    border-radius: 5px;
    font-size: 14px;
    width: 49%;
    height: 35px;
    margin: 2px 1px 0px 0px;
}

.Colors{
    color: #0d6efd;
    font-size: 21px;
}

.AbonChoice{
    width: 98%;
    color: #F1F1F1;
    border-color: #0d6efd00;
    outline-color: #0d6efd;
    background-color: #0d6efd;
    border-radius: 5px;
    font-size: 16px;
    min-height: 37px;
    margin: 4px 4px 4px 4px;
    padding-top: 6px;
}

.choiceId{
    min-height: 35px;
    padding-top: 10px;
    font-size: 17px;
    color: #0d6efd;
    text-align: left;
    font-weight: normal;
    padding-left: 10px;
}












